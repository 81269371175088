import React from 'react'
import { connect } from 'react-redux'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { getAdmin, createAdmin, deleteAdmin } from './../../store/app/action';
import {
    Route,
    NavLink,
    HashRouter,
    useHistory
} from "react-router-dom";

export const Admin = ({ getAdmin, datas_admin, createAdmin, isUpdate, deleteAdmin, isLogin }) => {
    // let history = useHistory();

    React.useEffect(() => {
        isLogin? console.log("true") :  console.log("false")
        getAdmin()
       
    }, [isUpdate, isLogin])

    const [modal, setModal] = React.useState(false)
    const [nama, setNama] = React.useState('')
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')

    const openModal = () => {
        setModal(!modal)
        if (modal === false) {
            setNama('')
            setUsername('')
            setPassword('')
        }
    }

    const submit = () => {
        const payload = {
            nama,
            username,
            password
        }
        createAdmin(payload)
    }

    const deleteadmin = (id) => {
        const payload = {
            id
        }
        deleteAdmin(payload)
    }

    return (
        <>
            <div className="content-container">
                <div className="content-header">
                    <div>
                        <h3 style={{ alignSelf: 'center' }}>Administrator Adhi Kresna</h3>
                    </div>
                    <div>
                        <MDBBtn color="primary" style={{ float: 'right' }} onClick={openModal}>Tambah Data</MDBBtn>
                    </div>
                </div>
                <div className="content-main">
                    <table id="customers">
                        <tr style={{ textAlign: 'center' }}>
                            <th>No</th>
                            <th>Nama</th>
                            <th>Username</th>
                            <th>Password</th>
                            <th>#</th>
                        </tr>
                        {datas_admin.length === 0 ? "Loading" :
                            datas_admin.map((v, i) =>
                                <tr key={i} >
                                    <td>{i + 1}</td>
                                    <td>{v.nama}</td>
                                    <td>{v.username}</td>
                                    <td>{v.password}</td>
                                    <td>
                                        <button className="btn btn-danger" onClick={() => deleteadmin(v.id)}><i className='bx bx-trash'></i>Hapus</button>
                                    </td>
                                </tr>
                            )}
                    </table>
                </div>
            </div>
            <MDBContainer>
                <MDBModal isOpen={modal} toggle={modal} centered>
                    <MDBModalHeader toggle={openModal}>Tambah Data</MDBModalHeader>
                    <MDBModalBody>
                        <div className="form-group">
                            <label htmlFor="example1">Nama</label>
                            <input type="text" id="example1" value={nama} onChange={e => setNama(e.target.value)} className="form-control form-control-md" placeholder="Nama" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="example1">Username</label>
                            <input type="text" id="example1" value={username} onChange={e => setUsername(e.target.value)} className="form-control form-control-md" placeholder="Adminname" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="example1">Password</label>
                            <input type="text" id="example1" value={password} onChange={e => setPassword(e.target.value)} className="form-control form-control-md" placeholder="Password" required />
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn onClick={submit} color="primary"  >Save changes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        </>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        datas_admin: app.datas_admin,
        isUpdate: app.isUpdate,
        isLogin: app.isLogin
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAdmin: () => dispatch(getAdmin()),
        createAdmin: payload => dispatch(createAdmin(payload)),
        deleteAdmin: payload => dispatch(deleteAdmin(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
