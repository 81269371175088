import React from 'react';
import { connect } from 'react-redux';
import { getDataGeo, getDataPen, getAgama, updateTrigatra } from './../../store/app/action';
import NumberFormat from 'react-number-format';

import './../style/styles.css';

export const Trigatra = ({ getDataGeo, datas_geo, updateTrigatra,luas_chart, getDataPen, datas_pen, getAgama, datas_agama }) => {

    React.useEffect(() => {
        getDataGeo()
        getDataPen()
        getAgama()
        console.log(luas_chart)
    }, [])

    const [parEdit, setParEdit] = React.useState('')
    const [parEditId, setParEditId] = React.useState('')
    const [par1, setPar1] = React.useState('')
    const [par2, setPar2] = React.useState('')
    const [par3, setPar3] = React.useState('')
    const [par4, setPar4] = React.useState('')
    const [par5, setPar5] = React.useState('')
    const [par6, setPar6] = React.useState('')
    const [par7, setPar7] = React.useState('')
    const [par8, setPar8] = React.useState('')

    const setEdit = (par, datas) => {

        setParEdit(par)
        setParEditId(datas.id)

        if (par === "geo") {
            setPar1(datas.luas)
        } else if (par === "batas") {
            setPar1(datas.batas_utara)
            setPar2(datas.batas_timur)
            setPar3(datas.batas_selatan)
            setPar4(datas.batas_barat)
        } else if (par === "tanah") {
            setPar1(datas.jt_organosol)
            setPar2(datas.jt_alluvial)
            setPar3(datas.jt_pedsol)
            setPar4(datas.jt_latosol)
            setPar5(datas.jt_pmk)
        } else if (par === "penduduk") {
            setPar1(datas.laki)
            setPar2(datas.perempuan)
        } else if (par === "agama") {
            setPar1(datas.islam)
            setPar2(datas.kristen)
            setPar3(datas.katholik)
            setPar4(datas.hindu)
            setPar5(datas.budha)
            setPar6(datas.konghuchu)
            setPar7(datas.alirankepercayaan)
        }
    }
    
    const submitEdit = () => {

        const payload = {
            setup : parEdit,
            id : parEditId,
            par1,
            par2,
            par3,
            par4,
            par5,
            par6,
            par7,
            par8,
        }
        console.log(payload)
        updateTrigatra(payload)
    }

    return (
        <>
            <div className="content-container">
                <div className="content-header">
                    <div>
                        <h3>Geografis</h3>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="content-main" style={{ overflowX:'auto' }}>
                    <table id="customers">
                        <tr>
                            <th>Kecamatan</th>
                            <th>Koordinat</th>
                            <th>Luas (ha)</th>
                            <th>#</th>
                        </tr>
                        {datas_geo.map((v, i) =>
                            parEdit === "geo" && parEditId === v.id ?
                                <tr key={i}>
                                    <td >{v.nama_kecamatan}</td>
                                    <td>{v.bujur} dan {v.lintang}</td>
                                    <td><input className="input-edit" value={par1} onChange={e => setPar1(e.target.value)} /></td>
                                    <td>
                                        <button className="btn btn-success" onClick={() => submitEdit()}> <i className='bx bx-save'></i> Simpan</button>
                                        <button className="btn btn-danger" onClick={() => setParEdit('')}><i className='bx bx-trash'></i>Cancel</button>
                                    </td>
                                </tr>
                                :
                                <tr key={i}>
                                    <td >{v.nama_kecamatan}</td>
                                    <td>{v.bujur} dan {v.lintang}</td>
                                    <td><NumberFormat
                                        value={v.luas}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={formattedValue => <span >{formattedValue} ha</span>}
                                    /></td>
                                    <td>
                                        <button className="btn btn-primary" onClick={() => setEdit("geo", v)}> <i className='bx bx-pencil'></i> Edit</button>
                                    </td>
                                </tr>
                        )}

                    </table>
                </div>
            </div>

            <div className="content-container">
                <div className="content-header">
                    <div>
                        <h3>Perbatasan</h3>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="content-main" style={{ overflowX:'auto' }}>
                    <table id="customers">
                        <tr>
                            <th>Kecamatan</th>
                            <th>Batas Utara</th>
                            <th>Batas Timur</th>
                            <th>Batas Selatan</th>
                            <th>Batas Barat</th>
                            <th>#</th>
                        </tr>
                        {datas_geo.map((v, i) =>
                            parEdit === "batas" && parEditId === v.id ?
                                <tr>
                                    <td>{v.nama_kecamatan}</td>
                                    <td><input value={par1} className="input-edit" onChange={e => setPar1(e.target.value)} /></td>
                                    <td><input value={par2} className="input-edit" onChange={e => setPar2(e.target.value)} /></td>
                                    <td><input value={par3} className="input-edit" onChange={e => setPar3(e.target.value)} /></td>
                                    <td><input value={par4} className="input-edit" onChange={e => setPar4(e.target.value)} /></td>
                                    <td>
                                        <button className="btn btn-success" onClick={() => submitEdit()}> <i className='bx bx-save'></i> Simpan</button>
                                        <button className="btn btn-danger" onClick={() => setParEdit('')}><i className='bx bx-trash'></i> Cancel</button>
                                    </td>
                                </tr>
                                :
                                <tr>
                                    <td> {v.nama_kecamatan}</td>
                                    <td>{v.batas_utara}</td>
                                    <td>{v.batas_timur}</td>
                                    <td>{v.batas_selatan}</td>
                                    <td>{v.batas_barat}</td>
                                    <td>
                                        <button className="btn btn-primary" onClick={() => setEdit("batas", v)}> <i className='bx bx-pencil'></i> Edit</button>
                                    </td>
                                </tr>
                        )}

                    </table>
                </div>
            </div>

            <div className="content-container">
                <div className="content-header">
                    <div>
                        <h3>Jenis tanah</h3>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="content-main" style={{ overflowX:'auto' }}>
                    <table id="customers">
                        <tr>
                            <th>Kecamatan</th>
                            <th>Organosol</th>
                            <th>Alluvial</th>
                            <th>Pedsol</th>
                            <th>Latosol</th>
                            <th>PMK</th>
                            <th>#</th>
                        </tr>
                        {datas_geo.map((v, i) =>
                            parEdit === "tanah" && parEditId === v.id ?
                                <tr>
                                    <td style={{ width: 250 }}> {v.nama_kecamatan}</td>
                                    <td><input value={par1} className="input-edit" onChange={e => setPar1(e.target.value)} /></td>
                                    <td><input value={par2} className="input-edit" onChange={e => setPar2(e.target.value)} /></td>
                                    <td><input value={par3} className="input-edit" onChange={e => setPar3(e.target.value)} /></td>
                                    <td><input value={par4} className="input-edit" onChange={e => setPar4(e.target.value)} /></td>
                                    <td><input value={par5} className="input-edit" onChange={e => setPar5(e.target.value)} /></td>
                                    <td>
                                        <button className="btn btn-success" onClick={submitEdit}> <i className='bx bx-save'></i> Simpan</button>
                                        <button className="btn btn-danger" onClick={() => setParEdit('')}><i className='bx bx-trash'></i>Cancel</button>
                                    </td>
                                </tr>
                                :
                                <tr>
                                    <td style={{ width: 250 }}> {v.nama_kecamatan}</td>
                                    <td>
                                        <NumberFormat
                                            value={v.jt_organosol}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={formattedValue => <span >{formattedValue} ha</span>}
                                        />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            value={v.jt_alluvial}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={formattedValue => <span >{formattedValue} ha</span>}
                                        />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            value={v.jt_pedsol}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={formattedValue => <span >{formattedValue} ha</span>}
                                        />
                                    </td>
                                    <td><NumberFormat
                                        value={v.jt_latosol}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={formattedValue => <span >{formattedValue} ha</span>}
                                    /></td>
                                    <td><NumberFormat
                                        value={v.jt_pmk}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={formattedValue => <span >{formattedValue} ha</span>}
                                    /></td>

                                    <td>
                                        <button className="btn btn-primary" onClick={() => setEdit("tanah", v)}> <i className='bx bx-pencil'></i> Edit</button>
                                    </td>
                                </tr>
                        )}

                    </table>
                </div>
            </div>

            <div className="content-container">
                <div className="content-header">
                    <div>
                        <h3>Penduduk</h3>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="content-main" style={{ overflowX:'auto' }}>
                    <table id="customers">
                        <tr>
                            <th>Kelurahan</th>
                            <th>Kecamatan</th>
                            <th>Laki Laki</th>
                            <th>Perempuan</th>
                            <th>Jumlah</th>
                            <th>#</th>
                        </tr>
                        {datas_pen.map((v, i) =>
                            parEdit === "penduduk" && parEditId === v.id ?
                                <tr>
                                    <td> {v.nama_kelurahan}</td>
                                    <td>{v.nama_kecamatan}</td>
                                    <td><input value={par1} className="input-edit" onChange={e => setPar1(e.target.value)} /></td>
                                    <td><input value={par2} className="input-edit" onChange={e => setPar2(e.target.value)} /></td>
                                    <td>{parseInt(v.laki) + parseInt(v.perempuan)}</td>
                                    <td>
                                        <button className="btn btn-success" onClick={submitEdit}> <i className='bx bx-save'></i> Simpan</button>
                                        <button className="btn btn-danger" onClick={() => setParEdit('')}><i className='bx bx-trash'></i> Cancel</button>
                                    </td>
                                </tr>

                                :
                                <tr>
                                    <td> {v.nama_kelurahan}</td>
                                    <td>{v.nama_kecamatan}</td>
                                    <td>{v.laki}</td>
                                    <td>{v.perempuan}</td>
                                    <td>{parseInt(v.laki) + parseInt(v.perempuan)}</td>
                                    <td>
                                        <button className="btn btn-primary" onClick={() => setEdit("penduduk", v)}> <i className='bx bx-pencil'></i> Edit</button>
                                    </td>
                                </tr>
                        )}

                    </table>
                </div>
            </div>

            <div className="content-container">
                <div className="content-header">
                    <div>
                        <h3>Agama</h3>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="content-main" style={{ overflowX:'auto' }}>
                    <table id="customers">
                        <tr style={{ textAlign: 'center' }}>
                            <th>Kecamatan</th>
                            <th>Islam</th>
                            <th>Kristen</th>
                            <th>Khatolik</th>
                            <th>Hindu</th>
                            <th>Budha</th>
                            <th>Kong Hu Chu</th>
                            <th>Aliran Kepercayaan</th>
                            <th>#</th>
                        </tr>
                        {datas_agama.map((v, i) =>
                            parEdit === "agama" && parEditId === v.id ?
                                <tr key={i} >
                                    <td>{v.nama_kecamatan}</td>
                                    <td><input value={par1} className="input-edit" onChange={e => setPar1(e.target.value)} /></td>
                                    <td><input value={par2} className="input-edit" onChange={e => setPar2(e.target.value)} /></td>
                                    <td><input value={par3} className="input-edit" onChange={e => setPar3(e.target.value)} /></td>
                                    <td><input value={par4} className="input-edit" onChange={e => setPar4(e.target.value)} /></td>
                                    <td><input value={par5} className="input-edit" onChange={e => setPar5(e.target.value)} /></td>
                                    <td><input value={par6} className="input-edit" onChange={e => setPar6(e.target.value)} /></td>
                                    <td><input value={par7} className="input-edit" onChange={e => setPar7(e.target.value)} /></td>
                                    <td>
                                        <button className="btn btn-success" onClick={submitEdit}> <i className='bx bx-save'></i> Simpan</button>
                                        <button className="btn btn-danger" onClick={() => setParEdit('')}><i className='bx bx-trash'></i> Cancel</button>
                                    </td>
                                </tr>
                                :
                                <tr key={i} >
                                    <td>{v.nama_kecamatan}</td>
                                    <td>
                                        <NumberFormat
                                            value={v.islam}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={formattedValue => <>{formattedValue} </>}
                                        />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            value={v.kristen}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={formattedValue => <>{formattedValue} </>}
                                        />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            value={v.katholik}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={formattedValue => <>{formattedValue} </>}
                                        />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            value={v.hindu}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={formattedValue => <>{formattedValue} </>}
                                        />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            value={v.budha}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={formattedValue => <>{formattedValue} </>}
                                        />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            value={v.konghuchu}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={formattedValue => <>{formattedValue} </>}
                                        />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            value={v.alirankepercayaan}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            renderText={formattedValue => <>{formattedValue} </>}
                                        />
                                    </td>
                                    <td>
                                        <button className="btn btn-primary"  onClick={() => setEdit("agama", v)}> <i className='bx bx-pencil'></i> Edit</button>
                                    </td>
                                </tr>
                        )}
                    </table>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        datas_geo: app.datas_geo,
        luas_chart: app.luas_chart,
        datas_pen: app.datas_pen,
        datas_agama: app.datas_agama
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDataGeo: () => dispatch(getDataGeo()),
        getDataPen: () => dispatch(getDataPen()),
        getAgama: () => dispatch(getAgama()),
        updateTrigatra: payload =>  dispatch(updateTrigatra(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Trigatra)
