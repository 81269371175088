import axios from 'axios';
import { notification } from 'antd';


const url = 'https://polres-singkawang.com/siadhi/public/api';


const storeDataGeo = (payload, chart) => ({ type: 'STORE_DATA_GEO', datas: payload, luas_chart: chart })
const storeDataPenduduk = (payload) => ({ type: 'STORE_DATA_PEN', datas: payload })
const storeDataAgama = (payload) => ({ type: 'STORE_DATA_AGAMA', datas: payload })
const storeSomeData = (payload) => ({ type: 'STORE_SOME_DATA', datas: payload })
const storePDF = (payload) => ({ type: 'STORE_PDF', datas: payload })
const setLoading = () => ({ type: 'SET_LOADING' })
const setUpdate = () => ({ type: 'SET_UPDATE' })
const storeUser = (payload) => ({ type: 'STORE_USER', datas: payload })
const storeAdmin = (payload) => ({ type: 'STORE_ADMIN', datas: payload })
const setLogin = () => ({ type: 'SET_LOGIN' })

export const cekLogin = () => {
    return (dispatch) => {
        const cek = localStorage.getItem("loginAdmin")
        console.log(cek)
        if (cek === true) {
            dispatch(setLogin())
        } else {
            console.log("do nothing...")
        }
    }
}

export const Login = (payload) => {
    return (dispatch) => {
        axios.post(`${url}/loginadmin`, payload)
            .then(response => {
                console.log(response.data.status)
                if (response.data.status === 1) {
                    localStorage.setItem("loginAdmin", true)
                    dispatch(setLogin())
                } else {
                    alert("Username atau Password salah")
                }

            })
    }
}

export const Logout = () => {
    return (dispatch) => {
        localStorage.setItem("loginAdmin", false)
        dispatch(setLogin())
    }
}

export const getDataGeo = () => {
    return (dispatch) => {
        axios.get(`${url}/getgeo`)
            .then(response => {
                let res = response.data.data
                let data = []
                res.map((v, i) =>
                    data.push({ 'name': v.nama_kecamatan, 'value': parseInt(v.luas) })
                )
                dispatch(storeDataGeo(res, data)) //Data untuk chart Luas
            })
    }
}

export const getDataPen = () => {
    return (dispatch) => {
        axios.get(`${url}/getpenduduk`)
            .then(response => {
                console.log(response.data.datas)
                dispatch(storeDataPenduduk(response.data.datas))
            })
    }
}

export const getAgama = () => {
    return (dispatch) => {
        axios.get(`${url}/getagama`)
            .then(response => {
                console.log(response.data)
                dispatch(storeDataAgama(response.data.data))
            })
    }
}

export const getSome = () => {
    return (dispatch) => {
        axios.get(`${url}/getsome`)
            .then(response => {
                console.log("SOME")
                dispatch(storeSomeData(response.data.data))
            })
    }
}

export const getPdf = () => {
    return (dispatch) => {
        axios.get(`${url}/getpdf`)
            .then(response => {
                console.log(response.data)
                dispatch(storePDF(response.data.data))
            })
    }
}

export const updateTrigatra = (payload) => {
    return (dispatch) => {
        console.log(payload)
        axios.post(`${url}/updatetrigatra`, payload)
            .then(response => {
                console.log(response.data)
                if (response.data.sukses === 1) {
                    if(payload.par === "agama"){
                        getAgama()
                    }
                    notification.open({
                        message: 'Updating data sukses',
                        description:
                            'Berhasil mengupdate data trigatra',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                } else {
                    alert("Gagal menambahkan data")
                }
            })
    }
}

export const createPancagatra = (payload) => {
    return (dispatch) => {
        axios.post(`${url}/createpancagatra`, payload)
            .then(response => {
                console.log(response.data)
                if (response.data.sukses === 1) {
                    dispatch(setUpdate())
                    getSome()
                    notification.open({
                        message: 'Sukses Menambahkan Data',
                        description:
                            'Berhasil menambahkan data Pancagatra',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                } else {
                    alert("Gagal menambahkan data")
                }
            })
    }
}

export const deletePancagatra = (payload) => {
    return (dispatch) => {
        axios.post(`${url}/deletepancagatra`, payload)
            .then(response => {
                console.log(response.data)
                if (response.data.sukses === 1) {
                    dispatch(setUpdate())
                    getSome()
                    notification.open({
                        message: 'Sukses Menghapus Data',
                        description:
                            'Berhasil Menghapus Data',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                } else {
                    alert("Gagal menambahkan data")
                }
            })
    }
}

export const getUser = () => {
    return (dispatch) => {
        axios.get(`${url}/getuser`)
            .then(response => {
                console.log(response.data)
                dispatch(storeUser(response.data.data))
            })
    }
}


export const createUser = (payload) => {
    return (dispatch) => {
        axios.post(`${url}/createuser`, payload)
            .then(response => {
                if (response.data.sukses === 1) {
                    dispatch(setUpdate())
                    getUser()
                    notification.open({
                        message: 'Sukses Menambahkan Pengguna',
                        description:
                            'Berhasil menambahkan data Pengguna',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                } else {
                    alert("Gagal menambahkan data")
                }
            })
    }
}

export const deleteUser = (payload) => {
    return (dispatch) => {
        axios.post(`${url}/deleteuser`, payload)
            .then(response => {
                if (response.data.sukses === 1) {
                    dispatch(setUpdate())
                    getUser()
                    notification.open({
                        message: 'Sukses Menghapus Pengguna',
                        description:
                            'Berhasil menghapus data Pengguna',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                } else {
                    alert("Gagal menambahkan data")
                }
            })
    }
}

//=================

export const getAdmin = () => {
    return (dispatch) => {
        axios.get(`${url}/getadmin`)
            .then(response => {
                console.log(response.data)
                dispatch(storeAdmin(response.data.data))
            })
    }
}


export const createAdmin = (payload) => {
    return (dispatch) => {
        axios.post(`${url}/createadmin`, payload)
            .then(response => {
                if (response.data.sukses === 1) {
                    dispatch(setUpdate())
                    getAdmin()
                    notification.open({
                        message: 'Sukses Menambahkan Adminitrator',
                        description:
                            'Berhasil menambahkan data Addministator',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                } else {
                    alert("Gagal menambahkan data")
                }
            })
    }
}

export const deleteAdmin = (payload) => {
    return (dispatch) => {
        axios.post(`${url}/deleteadmin`, payload)
            .then(response => {
                if (response.data.sukses === 1) {
                    dispatch(setUpdate())
                    getAdmin()
                    notification.open({
                        message: 'Sukses Menghapus Administrator',
                        description:
                            'Berhasil menghapus data Administrator',
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                } else {
                    alert("Gagal menambahkan data")
                }
            })
    }
}
