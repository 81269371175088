import React from 'react';
import { connect } from 'react-redux';
import { getSome, createPancagatra, deletePancagatra } from '../../store/app/action';
import { MDBDataTableV5, MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Table, Popconfirm, Button } from 'antd';
import {
    DeleteOutlined,
    CheckCircleOutlined,
    InfoCircleOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';

export const Pancagatra = ({ isUpdate, getSome, some_datas, isLoading, createPancagatra, deletePancagatra }) => {

    const [load, setLoad] = React.useState(0)
    const [dataTempatIbadah, setDataTempatIbadah] = React.useState();

    React.useEffect(() => {
        getAllData()
    }, [isLoading, isUpdate])

    const [modal, setModal] = React.useState(false)
    const [setup, seStup] = React.useState('')
    const [par1, setPar1] = React.useState('')
    const [par2, setPar2] = React.useState('')
    const [par3, setPar3] = React.useState('')
    const [par4, setPar4] = React.useState('')
    const [par5, setPar5] = React.useState('')

    const [dataForkopimda, setDataForkopimda] = React.useState();
    const [dataPemerintahan, setDataPemerintahan] = React.useState();
    const [dataOrmas, setDataOrmas] = React.useState();
    const [dataOKP, setDataOKP] = React.useState();
    const [dataTokoh, setDataTokoh] = React.useState();

    const openModal = (set) => {
        seStup(set)
        setModal(!modal)
        setPar1("")
        setPar2("")
        setPar3("")
        setPar4("")
        setPar5("")
    }

    const getAllData = async () => {
        console.log(isLoading)
        await getSome()
        
        if (!isLoading) {
            getTempatIbadahFunc()
            getForkopimda()
            getPemerintah()
            getYayasan()
            getOKP()
            getTokoh()
        }

        if(isUpdate === true){
            getTempatIbadahFunc()
            getForkopimda()
            getPemerintah()
            getYayasan()
            getOKP()
            getTokoh()
            if (modal) {openModal()}
        }
    }

    const getTempatIbadahFunc = async () => {
        const data = []
        const datas = some_datas.tempat_ibadah
        for (let i = 0; i < datas.length; i++) {
            await data.push({
                no: i + 1,
                id: datas[i].id,
                nama: datas[i].nama_tempat_ibadah,
                jenis: datas[i].jenis,
                alamat: datas[i].alamat,
                jamaah: datas[i].jlh_jemaah,
                kontak: datas[i].no_telp
            })
        }
        
        setDataTempatIbadah(data)
    }

    const getForkopimda = async () => {
        const data = []
        const datas = some_datas.forkopimda
        for (let i = 0; i < datas.length; i++) {
            await data.push({
                no: i + 1,
                id: some_datas.forkopimda[i].id,
                nama: some_datas.forkopimda[i].nama,
                jabatan: some_datas.forkopimda[i].jabatan,
                alamat: some_datas.forkopimda[i].alamat_kantor,
            })
        }
        setDataForkopimda(data)
        console.log(datas)
    }

    const getPemerintah = async () => {
        const data = []
        const datas = some_datas.pemerintahan
        for (let i = 0; i < datas.length; i++) {
            await data.push({
                no: i + 1,
                id: datas[i].id,
                nama: datas[i].nama_pejabat,
                jabatan: datas[i].jabatan,
                skpd: datas[i].nama_skpd,
                alamat: datas[i].alamat_skpd,
            })
        }
        setDataPemerintahan(data)
    }

    const getYayasan = async () => {
        const data = []
        const datas = some_datas.yayasan
        for (let i = 0; i < datas.length; i++) {
            await data.push({
                no: i + 1,
                id: datas[i].id,
                nama: datas[i].nama_ormas,
                ketua: datas[i].ketua,
                no_surat: datas[i].no_surat,
                alamat: datas[i].alamat,
                periode: datas[i].periode,
            })
        }
        setDataOrmas(data)
    }

    const getOKP = async () => {
        const data = []
        const datas = some_datas.okp
        for (let i = 0; i < datas.length; i++) {
            await data.push({
                no: i + 1,
                id: datas[i].id,
                nama: datas[i].nama_okp,
                ketua: datas[i].ketua,
                no_akta: datas[i].no_akta,
                alamat: datas[i].alamat,
            })
        }
        setDataOKP(data)
    }

    const getTokoh = async () => {
        const data = []
        const datas = some_datas.tokoh
        for (let i = 0; i < datas.length; i++) {
            await data.push({
                no: i + 1,
                id: datas[i].id,
                nama: datas[i].nama_tokoh,
                bidang: datas[i].bidang,
                jumlah_pendukung: datas[i].jumlah_pendukung,
                alamat: datas[i].alamat,
                no_telp: datas[i].no_telp,
            })
        }
        setDataTokoh(data)
    }

    const columns = [{
        tempat_ibadah: [
            {
                title: "No",
                key: 'no',
                dataIndex: 'no'
            },
            {
                title: "Nama Tempat Ibadah",
                key: 'nama',
                dataIndex: 'nama'
            },
            {
                title: "Kategori",
                key: 'jenis',
                dataIndex: 'jenis'
            },
            {
                title: "Alamat",
                key: 'alamat',
                dataIndex: 'alamat'
            },
            {
                title: "Jumlah Jamaah",
                key: 'jamaah',
                dataIndex: 'jamaah'
            },
            {
                title: "Kontak",
                key: 'kontak',
                dataIndex: 'kontak'
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        {/* <Button key="edit" onClick={() => getkoderekeningbyid(record.id)} style={{ marginLeft: 10 }} type="primary" icon={<InfoCircleOutlined />} >Edit</Button> */}
                        <Popconfirm
                            title="Anda yakin menghapus Data ini?"
                            onConfirm={() => deletePanca("ibadah", record.id)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button key="hapus" style={{ marginLeft: 10 }} type="danger" icon={<DeleteOutlined />} >Hapus</Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ],
        forkopimda: [
            {
                title: "No",
                key: 'no',
                dataIndex: 'no'
            },
            {
                title: "Nama Pejabat",
                key: 'nama',
                dataIndex: 'nama'
            },
            {
                title: "Jabatan",
                key: 'jabatan',
                dataIndex: 'jabatan'
            },
            {
                title: "Alamat Kantor",
                key: 'alamat',
                dataIndex: 'alamat'
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        {/* <Button key="edit" onClick={() => getkoderekeningbyid(record.id)} style={{ marginLeft: 10 }} type="primary" icon={<InfoCircleOutlined />} >Edit</Button> */}
                        <Popconfirm
                            title="Anda yakin menghapus Data ini?"
                            onConfirm={() => deletePanca("forkopimda", record.id)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button key="hapus" style={{ marginLeft: 10 }} type="danger" icon={<DeleteOutlined />} >Hapus</Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ],
        pemerintahan: [
            {
                title: "No",
                key: 'no',
                dataIndex: 'no'
            },
            {
                title: "Nama Pejabat",
                key: 'nama',
                dataIndex: 'nama'
            },
            {
                title: "Jabatan",
                key: 'jabatan',
                dataIndex: 'jabatan'
            },
            {
                title: "SKPD",
                key: 'skpd',
                dataIndex: 'skpd'
            },
            {
                title: "Alamat Kantor",
                key: 'alamat',
                dataIndex: 'alamat'
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        {/* <Button key="edit" onClick={() => getkoderekeningbyid(record.id)} style={{ marginLeft: 10 }} type="primary" icon={<InfoCircleOutlined />} >Edit</Button> */}
                        <Popconfirm
                            title="Anda yakin menghapus Data ini?"
                            onConfirm={() => deletePanca("pemerintahan", record.id)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button key="hapus" style={{ marginLeft: 10 }} type="danger" icon={<DeleteOutlined />} >Hapus</Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ],
        yayasan: [
            {
                title: "No",
                key: 'no',
                dataIndex: 'no'
            },
            {
                title: "Nama Ormas",
                key: 'nama',
                dataIndex: 'nama'
            },
            {
                title: "Ketua",
                key: 'ketua',
                dataIndex: 'ketua'
            },
            {
                title: "No Surat",
                key: 'no_surat',
                dataIndex: 'no_surat'
            },
            {
                title: "Alamat Kantor",
                key: 'alamat',
                dataIndex: 'alamat'
            },
            {
                title: "Periode",
                key: 'periode',
                dataIndex: 'periode'
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        {/* <Button key="edit" onClick={() => getkoderekeningbyid(record.id)} style={{ marginLeft: 10 }} type="primary" icon={<InfoCircleOutlined />} >Edit</Button> */}
                        <Popconfirm
                            title="Anda yakin menghapus Data ini?"
                            onConfirm={() => deletePanca("ormas", record.id)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button key="hapus" style={{ marginLeft: 10 }} type="danger" icon={<DeleteOutlined />} >Hapus</Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ],
        okp: [
            {
                title: "No",
                key: 'no',
                dataIndex: 'no'
            },
            {
                title: "Nama Organisasi",
                key: 'nama',
                dataIndex: 'nama'
            },
            {
                title: "Ketua",
                key: 'ketua',
                dataIndex: 'ketua'
            },
            {
                title: "No Akta",
                key: 'no_akta',
                dataIndex: 'no_akta'
            },
            {
                title: "Alamat Kantor",
                key: 'alamat',
                dataIndex: 'alamat'
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        {/* <Button key="edit" onClick={() => getkoderekeningbyid(record.id)} style={{ marginLeft: 10 }} type="primary" icon={<InfoCircleOutlined />} >Edit</Button> */}
                        <Popconfirm
                            title="Anda yakin menghapus Data ini?"
                            onConfirm={() => deletePanca("okp", record.id)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button key="hapus" style={{ marginLeft: 10 }} type="danger" icon={<DeleteOutlined />} >Hapus</Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ],
        tokoh: [
            {
                title: "No",
                key: 'no',
                dataIndex: 'no'
            },
            {
                title: "Nama Tokoh",
                key: 'nama',
                dataIndex: 'nama'
            },
            {
                title: "Jabatan",
                key: 'bidang',
                dataIndex: 'bidang'
            },
            {
                title: "Jumlah Pendukung",
                key: 'jumlah_pendukung',
                dataIndex: 'jumlah_pendukung'
            },
            {
                title: "Alamat",
                key: 'alamat',
                dataIndex: 'alamat'
            },
            {
                title: "Kontak",
                key: 'no_telp',
                dataIndex: 'no_telp'
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        {/* <Button key="edit" onClick={() => getkoderekeningbyid(record.id)} style={{ marginLeft: 10 }} type="primary" icon={<InfoCircleOutlined />} >Edit</Button> */}
                        <Popconfirm
                            title="Anda yakin menghapus Data ini?"
                            onConfirm={() => deletePanca("tokoh", record.id)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button key="hapus" style={{ marginLeft: 10 }} type="danger" icon={<DeleteOutlined />} >Hapus</Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ],
    }]


    const createPanca = () => {
        const payload = {
            setup,
            par1,
            par2,
            par3,
            par4,
            par5
        }
        createPancagatra(payload)
    }

    const deletePanca = (par, id) => {
        const payload = {
            setup: par,
            id: id
        }
        deletePancagatra(payload)
    }

    return (
        <>
            {isLoading ? "Loading..." : <>
                <div className="content-container">
                    <div className="content-header">
                        <div>
                            <h3>Forkopimda</h3>
                        </div>
                        <div>
                            <MDBBtn color="primary" style={{ float: 'right' }} onClick={() => openModal("forkopimda")}>Tambah Data</MDBBtn>
                        </div>
                    </div>
                    <div className="content-main" style={{ overflowX:'auto' }}>
                        <Table id="customers" columns={columns[0].forkopimda} dataSource={dataForkopimda} />
                    </div>
                </div>

                <div className="content-container">
                    <div className="content-header">
                        <div>
                            <h3>Pemerintahan</h3>
                        </div>
                        <div>
                            <MDBBtn color="primary" style={{ float: 'right' }} onClick={() => openModal("pemerintahan")}>Tambah Data</MDBBtn>
                        </div>
                    </div>
                    <div className="content-main" style={{ overflowX:'auto' }}>
                        <Table id="customers" columns={columns[0].pemerintahan} dataSource={dataPemerintahan} />
                    </div>
                </div>

                <div className="content-container">
                    <div className="content-header">
                        <div>
                            <h3>Tempat Ibadah</h3>
                        </div>
                        <div>
                            <MDBBtn color="primary" style={{ float: 'right' }} onClick={() => openModal("ibadah")}>Tambah Data</MDBBtn>
                        </div>
                    </div>
                    <div className="content-main" style={{ overflowX:'auto' }}>
                        <Table id="customers" columns={columns[0].tempat_ibadah} dataSource={dataTempatIbadah} />
                    </div>
                </div>

                <div className="content-container">
                    <div className="content-header">
                        <div>
                            <h3>Yayasan / Organisasi Masyarakat</h3>
                        </div>
                        <div>
                            <MDBBtn color="primary" style={{ float: 'right' }} onClick={() => openModal("ormas")}>Tambah Data</MDBBtn>
                        </div>
                    </div>
                    <div className="content-main" style={{ overflowX:'auto' }}>
                        <Table id="customers" columns={columns[0].yayasan} dataSource={dataOrmas} />
                    </div>
                </div>

                <div className="content-container">
                    <div className="content-header">
                        <div>
                            <h3>Organisasi Kemasyarakatan Pemuda</h3>
                        </div>
                        <div>
                            <MDBBtn color="primary" style={{ float: 'right' }} onClick={() => openModal("okp")}>Tambah Data</MDBBtn>
                        </div>
                    </div>
                    <div className="content-main" style={{ overflowX:'auto' }}>
                        <Table id="customers" columns={columns[0].okp} dataSource={dataOKP} />
                    </div>
                </div>

                <div className="content-container">
                    <div className="content-header">
                        <div>
                            <h3>Tokoh Masyarakat</h3>
                        </div>
                        <div>
                            <MDBBtn color="primary" style={{ float: 'right' }} onClick={() => openModal("tokoh")}>Tambah Data</MDBBtn>
                        </div>
                    </div>
                    <div className="content-main" style={{ overflowX:'auto' }}>
                        <Table id="customers" columns={columns[0].tokoh} dataSource={dataTokoh} />
                    </div>
                </div>

                <MDBContainer>
                    <MDBModal isOpen={modal} toggle={modal} centered>
                        <MDBModalHeader toggle={openModal}>Tambah Data</MDBModalHeader>
                        <MDBModalBody>

                            <div className="form-group">
                                <label htmlFor="example1">{setup === 'forkopimda' ? "Nama Pejabat" : setup === "pemerintahan" ? "Nama Pejabat" : setup === "ibadah" ? "Nama Tempat Ibadah" : setup === "ormas" ? "Nama Yayasan / Organisasi Masyarakat" : setup === "okp" ? "Nama OKP" : "Nama Tokoh"}</label>
                                <input type="text" id="example1" value={par1} onChange={(e) => setPar1(e.target.value)} className="form-control form-control-md" placeholder={setup === 'forkopimda' ? "Nama Pejabat" : setup === "pemerintahan" ? "Nama Pejabat" : setup === "ibadah" ? "Nama Tempat Ibadah" : setup === "ormas" ? "Nama Yayasan / Organisasi Masyarakat" : setup === "okp" ? "Nama OKP" : "Nama Tokoh"} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="example2">{setup === 'forkopimda' ? "Jabatan" : setup === "pemerintahan" ? "Jabatan" : setup === "ibadah" ? "Jenis Tempat Ibadah" : setup === "ormas" ? "Ketua" : setup === "okp" ? "Ketua" : "Jabatan"}</label>
                                {setup === "ibadah" ?
                                    <select
                                        className="form-control form-control-md"
                                        onChange={(e) => setPar2(e.target.value)}
                                    >
                                        <option>Pilih Jenis Tempat Ibadah</option>
                                        <option value="Masjid" >Masjid</option>
                                        <option value="Mushalla" >Surau / Mushalla</option>
                                        <option value="Gereja Khatolik" >Gereja Khatolik</option>
                                        <option value="Gereja Prostestan" >Gereja Protestan</option>
                                        <option value="Vihara" >Vihara / Klenteng</option>

                                    </select>
                                    :
                                    <input type="text" id="example2" value={par2} onChange={(e) => setPar2(e.target.value)} className="form-control form-control-md" placeholder={setup === 'forkopimda' ? "Jabatan" : setup === "pemerintahan" ? "Jabatan" : setup === "ibadah" ? "Jenis Tempat Ibadah" : setup === "ormas" ? "Ketua" : setup === "okp" ? "Ketua" : "Jabatan"} />
                                }

                            </div>

                            <div className="form-group">
                                <label htmlFor="example3">{setup === 'forkopimda' ? "Alamat" : setup === "pemerintahan" ? "SKPD" : setup === "ibadah" ? "Alamat" : setup === "ormas" ? "No Surat" : setup === "okp" ? "No Akta" : "Jumlah Pendukung"}</label>
                                <input type="text" id="example3" value={par3} onChange={(e) => setPar3(e.target.value)} className="form-control form-control-md" placeholder={setup === 'forkopimda' ? "Alamat" : setup === "pemerintahan" ? "SKPD" : setup === "ibadah" ? "Alamat" : setup === "ormas" ? "No Surat" : setup === "okp" ? "No Akta" : "Jumlah Pendukung"} />
                            </div>
                            {setup === "forkopimda" ? "" :
                                <div className="form-group">
                                    <label htmlFor="example3">{setup === 'forkopimda' ? "-" : setup === "pemerintahan" ? "Alamat Kantor" : setup === "ibadah" ? "Kontak" : setup === "ormas" ? "Alamat" : setup === "okp" ? "Alamat" : "Alamat"}</label>
                                    <input type="text" id="example3" value={par4} onChange={(e) => setPar4(e.target.value)} className="form-control form-control-md" placeholder={setup === 'forkopimda' ? "-" : setup === "pemerintahan" ? "Alamat Kantor" : setup === "ibadah" ? "Kontak" : setup === "ormas" ? "Alamat" : setup === "okp" ? "Alamat" : "Alamat"} />
                                </div>
                            }
                            {setup === "forkopimda" || setup === "pemerintahan" || setup === "okp" ? "" :
                                <div className="form-group">
                                    <label htmlFor="example3">{setup === 'forkopimda' ? "-" : setup === "pemerintahan" ? "Alamat Kantor" : setup === "ibadah" ? "Jumlah Jamaan" : setup === "ormas" ? "Periode" : setup === "okp" ? "Alamat" : "No HP"}</label>
                                    <input type="text" id="example3" value={par5} onChange={(e) => setPar5(e.target.value)} className="form-control form-control-md" placeholder={setup === 'forkopimda' ? "-" : setup === "pemerintahan" ? "Alamat Kantor" : setup === "ibadah" ? "Jumlah Jamaah" : setup === "ormas" ? "Periode" : setup === "okp" ? "Alamat" : "No HP"} />
                                </div>
                            }

                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={createPanca} >Save changes</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </>
            }

        </>
    )
}


const mapStateToProps = ({ app }) => {
    return {
        some_datas: app.some_datas,
        isLoading: app.isLoading,
        isUpdate: app.isUpdate
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSome: () => dispatch(getSome()),
        createPancagatra: payload => dispatch(createPancagatra(payload)),
        deletePancagatra: payload => dispatch(deletePancagatra(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Pancagatra)
