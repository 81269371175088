import React from 'react'
import { connect } from 'react-redux'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { getPdf} from './../../store/app/action';
import axios from 'axios';

export const Covid = ({getPdf, datas_pdf}) => {

    React.useEffect(() => {
        getPdf()
    }, [])

    const [modal, setModal] = React.useState(false)
    const [file, setPDF] = React.useState('')

    const openModal = () => {
        setModal(!modal)
    }

    const upload = () => {
        console.log(file)
      
        const dataArray = new FormData();
        dataArray.append("file", file);
        dataArray.append("keterangan", "covid");

        axios.post('https://polres-singkawang.com/siadhi/public/api/upload', dataArray)
        .then(res => {
            console.log(res)
            openModal()
            getPdf()
        })
    }

    return (
        <>
            <div className="content-container">
                <div className="content-header">
                    <div>
                        <h3 style={{ alignSelf: 'center' }}>COVID 19 Kota Singkawang</h3>
                    </div>
                    <div>
                        <MDBBtn color="primary" style={{ float: 'right' }} onClick={openModal}>Tambah Data</MDBBtn>
                    </div>
                </div>
                <div className="content-main">
                <table id="customers">
                        <tr style={{ textAlign: 'center' }}>
                            <th>No</th>
                            <th>File</th>
                            <th>Tanggal Publish</th>
                        
                        </tr>
                        {datas_pdf.length === 0 ? "Loading" :
                        datas_pdf.covid.map((v, i) =>
                            <tr key={i} >
                                <td>{i+1}</td>
                                <td>{v.nama_file}</td>
                                <td>{v.created_at}</td>
                               
                            </tr>
                        )}
                    </table>
                </div>
            </div>
            <MDBContainer>
                <MDBModal isOpen={modal} toggle={modal} centered>
                    <MDBModalHeader toggle={openModal}>Tambah Data</MDBModalHeader>
                    <MDBModalBody>
                        <input
                            type="file"
                            name="file"
                            aria-describedby="inputGroupFileAddon01"
                            onChange={(e) => setPDF(e.target.files[0])}
                        />
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={upload} >Save changes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        </>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        datas_pdf: app.datas_pdf,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPdf: () => dispatch(getPdf()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Covid)
