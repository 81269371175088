import React from 'react'
import { connect } from 'react-redux'
import {
    Route,
    NavLink,
    HashRouter
} from "react-router-dom";
import { cekLogin, Logout } from './../store/app/action';

import Pancagatra from './pages/Pancagatra';
import Trigatra from './pages/Trigatra';
import Kalender from './pages/Kalender';
import Covid from './pages/Covid';
import User from './pages/Users';
import Admin from './pages/Admin';
import Login from './auth/login';
import Vaksin from './pages/Vaksinasi';

import './style/style.css';

export const RootApp = ({ isLogin, cekLogin, Logout }) => {

    React.useEffect(() => {
        cekLogin()
        // isLogin ? console.log("Login") : window.location.href="/"
    }, [isLogin])

    const sideBarEvent = () => {
        document.querySelector(".sidebar").classList.toggle('open')
        menuBtnChange()
    }

    const menuBtnChange = () => {
        if (document.querySelector(".sidebar").classList.contains("open")) {
            document.querySelector("#btn").classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
        } else {
            document.querySelector("#btn").classList.replace("bx-menu-alt-right", "bx-menu");//replacing the iocns class
        }
    }


    return (
        <HashRouter>
            {isLogin ?
                <div>
                    <div className="sidebar open">
                        <div className="logo-details">
                            <i className='bx bxl-figma icon'></i>
                            <div className="logo_name">Adhi Kresna</div>
                            <i className='bx bx-menu' id="btn" onClick={sideBarEvent}></i>
                        </div>
                        <ul className="nav-list">
                            {/* <li>
                            <i className='bx bx-search' ></i>
                            <input type="text" placeholder="Search..." />
                            <span className="tooltip">Search</span>
                        </li> */}
                            <li>
                                <NavLink to="/">
                                    <i className='bx bx-grid-alt'></i>
                                    <span className="links_name">Trigatra</span>
                                </NavLink>
                                <span className="tooltip">Trigatra</span>
                            </li>
                            <li>
                                <NavLink to="/pancagatra">
                                    <i className='bx bx-user' ></i>
                                    <span className="links_name">Pancagatra</span>
                                </NavLink>
                                <span className="tooltip">Pancagatra</span>
                            </li>
                            <li>
                                <NavLink to="/kalender">
                                    <i className='bx bx-calendar' ></i>
                                    <span className="links_name">Kalender</span>
                                </NavLink>
                                <span className="tooltip">Kalender</span>
                            </li>
                            <li>
                                <NavLink to="/covid">
                                    <i className='bx bxs-virus' ></i>
                                    <span className="links_name">Covid 19</span>
                                </NavLink>
                                <span className="tooltip">Covid 19</span>
                            </li>
                            <li>
                                <NavLink to="/vaksin">
                                    <i className='bx bxs-eyedropper' ></i>
                                    <span className="links_name">Vaksinasi</span>
                                </NavLink>
                                <span className="tooltip">Vaksinasi</span>
                            </li>
                            <li>
                                <NavLink to="/user">
                                    <i className='bx bxs-user' ></i>
                                    <span className="links_name">User</span>
                                </NavLink>
                                <span className="tooltip">User</span>
                            </li>
                            <li>
                                <NavLink to="/admin">
                                    <i className='bx bxs-key' ></i>
                                    <span className="links_name">Administrator</span>
                                </NavLink>
                                <span className="tooltip">Administrator</span>
                            </li>
                            <li className="profile">
                                <div className="profile-details">
                                    <img src="https://e7.pngegg.com/pngimages/529/336/png-clipart-swat-computer-icons-police-avatar-swat-police-officer-people-thumbnail.png" alt="profileImg" />
                                    <div className="name_job">
                                        <div className="name">Administrator</div>
                                        <div className="job"></div>
                                    </div>
                                </div>
                                <button><i className='bx bx-log-out' id="log_out" onClick={Logout}></i></button>
                            </li>
                        </ul>
                    </div>
                    <section className="home-section">
                        <Route exact path="/" component={Trigatra} />
                        <Route path="/pancagatra" component={Pancagatra} />
                        <Route path="/kalender" component={Kalender} />
                        <Route path="/covid" component={Covid} />
                        <Route path="/vaksin" component={Vaksin} />
                        <Route path="/user" component={User} />
                        <Route path="/admin" component={Admin} />
                    </section>
                </div>
                :
                <Route exact path="/" component={Login} />
            }
        </HashRouter>
    )
}

const mapStateToProps = ({ app }) => {
    return {
        isLogin: app.isLogin,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        cekLogin: () => dispatch(cekLogin()),
        Logout: () => dispatch(Logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RootApp)
